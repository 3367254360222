<template>
  <atoms-menu-item
    padding="small"
    theme="global-navbar"
    class="menu-other max-w-12"
    @click="handleNotificationClick"
  >
    <div
      class="relative min-w-5 py-0.5"
      :class="{
        'min-w-6': notificationCount > 9,
        'min-w-7': notificationCount > 99
      }"
    >
      <NuxtImg
        src="/icons/bell.svg"
        alt="notifications"
        width="16"
        height="20"
      />
      <span
        class="absolute top-0 right-0 bg-red-600 text-white text-[8px] rounded-full min-w-3 h-3 flex items-center justify-center px-1 py-0.5"
        :class="{
          'hidden': !notificationCount
        }"
      >
        {{ notificationCount > 99 ? '99+' : notificationCount }}
      </span>
    </div>
  </atoms-menu-item>
</template>

<script setup>
import { useUserStore } from '@/stores/user'

defineOptions({
  name: 'MoleculesNavNotifications'
})

const notificationCount = ref(0)
const userStore = useUserStore()

function updateNotificationCount () {
  if (typeof window !== 'undefined' && window.braze) {
    window.braze.requestContentCardsRefresh()
    // window.braze.subscribeToContentCardsUpdates(cards => {
    //   notificationCount.value = cards?.cards.length
    // })
    notificationCount.value = window.braze.getCachedContentCards().getUnviewedCardCount()
  } else {
    // eslint-disable-next-line no-console
    console.error('Braze is not available')
  }
}

function handleNotificationClick () {
  if (typeof window !== 'undefined' && window.braze) {
    window.braze.toggleContentCards()
  } else {
    // eslint-disable-next-line no-console
    console.error('Braze is not available')
  }
}

function waitForBraze () {
  return new Promise ( resolve => {
    const interval = setInterval(() => {
      if (typeof window !== 'undefined' && window.braze) {
        clearInterval(interval)
        resolve()
      }
    }, 500)
  })
}

onMounted(async () => {
  try {
    const userID = userStore.getUserProfile?.hash_id

    await waitForBraze()
    if (userID) {
      // Set the current user's external ID before starting a new session
      window.braze.changeUser(userID)
      // Open a new session
      window.braze.openSession()
    }

    setTimeout(() => {
      updateNotificationCount()
    }, 2000)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error waiting for Braze:', error)
  }
})
</script>